import React from "react";
import { Form, Input, Button, Typography, Alert } from "antd";

import { ArrowLeftOutlined, SendOutlined } from "@ant-design/icons";
const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const { Link } = Typography;
const ForgotPassword = (props) => {
    const { component, message, loading } = props
    return (
        <Form
            {...layout}
            layout="vertical"
            name="basic"
            initialValues={{
                remember: true,
            }}

            size="large"
            onFinish={props.onForgotPassword}
            className="new-login-form"
        >
            <div className="form-title">
                <img src="/assets/images/dash-logo.png" alt="Dash Booking Logo" style={{ borderRadius: "5px" }} height="40" />
                <h1 >Reset your Password</h1>
            </div>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        type: "email",
                        message: "Please input valid email!",
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item style={{ width: "100%", textAlign: "center" }}>
                <Button
                    type="primary"
                    shape="round"
                    icon={<SendOutlined />}
                    style={{ width: "150px", backgroundColor: "#0097B2" }}
                    htmlType="submit"
                    loading={component === "ForgotPassword" && loading}
                >
                    Submit
                </Button>
            </Form.Item>

            <Form.Item style={{ width: "100%", textAlign: "center", }}>
                <Link onClick={() => props.userChooseForgotPassword()} style={{ color: "#0097B2" }}>
                    <ArrowLeftOutlined /> Back to Login
                </Link>
            </Form.Item>


            {message && component === "ForgotPassword" &&
                <Alert
                    message="Success "
                    description={message}
                    type="success"
                    showIcon
                />
            }
        </Form>
    );
};

export default ForgotPassword;
