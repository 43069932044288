import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Row, Col } from "antd";
import { SendOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import queryString from "query-string"
import { resetPassword } from "../../redux/actions/salon-account/authenticate"
import { NewMessageError } from "../../component/common/MessageError/NewMessageError";
const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
let currentYear = new Date();

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
// const { Title } = Typography;
class ResetPassword extends Component {

    state = {
        token: null
    }

    componentDidMount() {
        const queryObject = queryString.parse(this.props.history.location.search);
        const token = queryObject.token
        if (token) {
            this.setState({ token })
        }

    }

    componentDidUpdate() {
        const { error, message } = this.props
        NewMessageError(message, error, null)
        if (message) {
            window.location.href = "https://www.partnersdash.com";
        }


    }

    onFinish = (values) => {
        const { newPassword } = values
        const { token } = this.state
        this.props.resetPassword(token, newPassword)
    };

    render() {
        const { loading } = this.props


        const onFinishFailed = (errorInfo) => {

        };

        return (
            <Row justify="center" style={{ overflow: "scroll", height: "100%" }}>
                {/* <div className="block">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */}
                <Col xs={24} sm={24} md={16} lg={14} xl={10} xxl={8} style={{ marginTop: "50px", }}>
                    <Form
                        {...layout}
                        layout="vertical"
                        name="basic"
                        onFinish={this.onFinish}
                        onFinishFailed={onFinishFailed}
                        size="large"
                        // className="login-form"
                        className="new-login-form"
                    >
                        <div className="form-title">
                            <img src="/assets/images/dash-logo.png" alt="Dash Booking Logo" style={{ borderRadius: "5px" }} height="40" />
                            <h1 className="animated bounceIn">
                                Reset Password
                            </h1>
                        </div>

                        <Form.Item
                            label="New Password"
                            name="newPassword"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your new password!",
                                },
                                {
                                    validator: async (_, npw) => {
                                        if (!PASSWORD_REGEX.test(npw)) {
                                            return Promise.reject(
                                                new Error(
                                                    "Password does not meet the requirements"
                                                )
                                            );
                                        }
                                    },
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder="Your New Password"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Verify Password"
                            name="verifyPassword"
                            dependencies={["newPassword"]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Please confirm your password!",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue("newPassword") ===
                                            value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "The two passwords that you entered do not match!"
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder="Verify New Password"
                            />
                        </Form.Item>
                        <Row>
                            <h3>Password requirements:</h3>
                            <ul>
                                <li>
                                    Contain at least 1 lowercase alphabetical
                                    character
                                </li>
                                <li>
                                    Contain at least 1 uppercase alphabetical
                                    character
                                </li>
                                <li>Contain at least 1 numeric character</li>
                                {/* <li>
                                    Contain at least 1 special
                                    character(!@#$%^&*)
                                </li> */}
                                <li>Must be 8 characters or longer</li>
                            </ul>
                        </Row>

                        <Form.Item style={{ width: "100%", textAlign: "center" }}>
                            <Button
                                type="primary"
                                shape="round"
                                icon={<SendOutlined />}
                                style={{ backgroundColor: "#0097B2" }}
                                htmlType="submit"
                                loading={loading}
                            >
                                Reset Password
                            </Button>
                        </Form.Item>
                        <Form.Item style={{ width: "100%", textAlign: "center" }} >
                            <Button href="/" type="link" style={{ color: "#0097B2" }}>
                                <ArrowLeftOutlined />  Back to Login
                            </Button>
                        </Form.Item>
                    </Form>
                    <div
                        // className="copy-right-footer"
                        style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
                    >
                        <p style={{ margin: "0px" }}>
                            {currentYear.getFullYear()} &copy; Dash Booking Inc.
                        </p>
                        <a
                            href="https://www.dashbooking.com/business/partners-terms-and-conditions"
                            rel="noreferrer"
                            target="_blank"
                            style={{ color: "#0097B2", }}
                        >
                            Terms and Policy
                        </a>
                        <a style={{ marginLeft: "15px", color: "#0097B2", }} href="https://www.dashbooking.com/privacy-policy" rel="noreferrer"
                            target="_blank">Privacy Policy</a>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.authenticate.loading,
    error: state.authenticate.error,
    message: state.authenticate.message
});
function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (token, password) => dispatch(resetPassword(token, password))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
